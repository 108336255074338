import {inject, Injectable} from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpRequestService } from '../../core/services/http-request/http-request.service';
import { ResponseBase, Response } from '../../core/models/response.model';
import { VincularInstituicaoParams } from '../vincular-conta/vincular-instituicao/vincular-instituicao.model';
import {AlterarFotoPerfilModel, InformacoesPessoaisModel} from './usuario.model';
import { IntituicaoUsuarioModel } from './perfil/instituicoes/instituicoes.model';
import {AutenticacaoService} from "../autenticacao/autenticacao.service";

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private _httpRequest = inject(HttpRequestService);
  private _autenticacaoService = inject(AutenticacaoService);

  /**
   * Requisição HTTP para realizar o vínculo de uma nova instituição ao usuário
   */
  vincularInstituicao(
    params: VincularInstituicaoParams
  ): Observable<ResponseBase> {
    return this._httpRequest.post<ResponseBase>(
      '/v1/usuario/vincular-instituicao',
      params
    );
  }

  /**
   * Requisição HTTP para realizar a troca de foto do usuário
   */
  alterarAvatar(psAvatar?: string): Observable<ResponseBase> {
    let params: AlterarFotoPerfilModel = {
      avatar: psAvatar,
    };

    return this._httpRequest
      .put<ResponseBase>('/v1/usuario/alterar-foto', params)
      .pipe(
        tap((p) => {
          if (p.success) {
            this._autenticacaoService.atualizarDadosUsuarioLogado({ avatar: psAvatar });
          }
        })
      );
  }

  /**
   * Requisição HTTP para realizar a busca de instituições vinculadas ao usuário
   */
  obterInstituicoes(): Observable<Response<IntituicaoUsuarioModel[]>> {
    return this._httpRequest.get<Response<IntituicaoUsuarioModel[]>>(
      '/v1/usuario/instituicoes'
    );
  }

  /**
   * Requisição HTTP para realizar a busca de informações pessoais do usuário
   */
  obterInformacoesPessoais(): Observable<Response<InformacoesPessoaisModel>> {
    return this._httpRequest.get<Response<InformacoesPessoaisModel>>(
      '/v1/usuario/info'
    );
  }

  /**
   * Requisição HTTP para atualizar as informações pessoais do usuário
   */
  atualizarInformacoesPessoais(params: InformacoesPessoaisModel): Observable<ResponseBase> {
    return this._httpRequest.put<ResponseBase>(
      '/v1/usuario/info', params
    ).pipe(
      tap((p) => {
        if (p.success) {
          this._autenticacaoService.atualizarDadosUsuarioLogado({ nomeCompleto: params.nomeCompleto, apelido: params.apelido });
        }
      })
    );
  }
}
